import React, { useState, useEffect } from 'react';

import AircallPhone from 'aircall-everywhere';
import JSONPretty from 'react-json-pretty';

import {api_url ,AIRCALL_API_URL, oauth_clientID, oauth_secret, oauth_redirect_uri}  from '../config/config';

import  {history}  from '../helper/history';

function OutboundCallingPage() {


  const [userdata, setUserdata] = useState({});
  const [phoneloginButton, setPhoneloginButton] = useState(true);
  const [phonelDialButton, setPhonelDialButton] = useState(false);
 
  const [step1_loading, setStep1_loading] = useState(false);
  const [step2_loading, setStep2_loading] = useState(false);

  const [outdialResponse, setOutdialResponse] = useState('');
  const [paymentProcess, setPaymentProcess] = useState('');
  

  const [number, setNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [amount, setAmount] = useState('');


  const [step1, setStep1] = useState('block');
  const [step2, setStep2] = useState('none');
  const [step3, setStep3] = useState('none');

  const [paymentStatus, setPaymentStatus] = useState(false);
  const [paymentResponse, setPaymentResponse] = useState(false);

  const paytia_apikey = localStorage.getItem('paytia_apikey');
  
  

// for call flow events, create an item with message and payload
const addCallLog = (id, payload, log) => {
  console.log('addCallLog');
  console.log(id);
  console.log(payload);
  console.log(log);

  // we remove waiting text
  const waitingText = document.querySelector('#waiting-events');
  if (waitingText) {
    waitingText.remove();
  }
  // we add item
  const logBox = document.querySelector('#call-events-log');
  const d = document.createElement('div');
  const currentTime = new Date(Date.now());
  const htmlBlock = `<input type="checkbox" id="${id}-${payload.call_id}"><label for="${id}-${
    payload.call_id
  }"><span>${currentTime.toLocaleTimeString()}: ${log}</span><pre class="prettyprint"><code>${ JSON.stringify(payload, null, 2)}</code></pre></label>`;
  d.innerHTML = htmlBlock;
  //logBox.appendChild(d);
};

// write a payload and label in a code box
const setStatusData = (selector, data, label) => {
  const dataBox = document.querySelector(selector);
  const toPrettify = `${label}\n${JSON.stringify(data, null, 2)}`;
  //dataBox.innerHTML = window.PR.prettyPrintOne(toPrettify);
  setUserdata(toPrettify);
};

// write a mesage in an alert box
const setStatusMessage = (selector, type, message) => {
  const statusBox = document.querySelector(selector);
  statusBox.classList.remove('alert-danger', 'alert-success', 'alert-warning');
  statusBox.classList.add(`alert-${type}`);
  statusBox.textContent = message;
};

// Show phone
const setPhoneVisibility = visible => {
  const phoneContainer = document.querySelector('#phone-container');
  if (visible) {
    document.querySelector('#phone-container').classList.remove('d-none');
  } else {
    document.querySelector('#phone-container').classList.add('d-none');
  }
};

  // our buttons elements
  const loadPhoneButton = document.querySelector('#load-phone-button');
  const dialButton = document.querySelector('#dial-button');
  const isLoginButton = document.querySelector('#is-login-button');
  
  const load_aircall_screen = () => {

    console.log('load_aircall_screen');

    setPhonelDialButton(false);
    setPhoneloginButton(false);

    // phone visibility
    setPhoneVisibility(true);


    const phone = new AircallPhone({
      onLogin: settings => {
        console.log('phone loaded');
        console.log(settings);
        setStep1('block');  
        //setStatusData('#user-info', settings, '// user informations');
        setStatusMessage('#phone-loading', 'success', 'Phone is loaded and ready to use!');
      },
      onLogout: () => {
        console.log('logout');
        //setStatusData('#user-info', '', '// user informations');
        setStatusMessage('#phone-loading', 'danger', 'Phone is not loaded or logged in');
      },
      domToLoadPhone: '#phone',
      integrationToLoad: 'zendesk',
      size: 'big'
    });


    // listeners
    // incoming call
    phone.on('incoming_call', callInfos => {
      console.log('incoming_call');
      setPhoneVisibility(true);
      const message = `Incoming call from ${callInfos.from} to ${callInfos.to} ringing!`;
      addCallLog('incoming_call', callInfos, message);
      setStatusMessage('#call-events', 'success', message);
    });

    // ringtone ended
    phone.on('call_end_ringtone', callInfos => {
      console.log('call_end_ringtone');
      const message = `Ringing ended. call was ${callInfos.answer_status}`;
      addCallLog('call_end_ringtone', callInfos, message);
      setStatusMessage(
        '#call-events',
        callInfos.answer_status === 'answered' ? 'success' : 'warning',
        message
      );
    });

    // call ended
    phone.on('call_ended', callInfos => {
      console.log('call_ended');
      const message = `Call ended. Lasted ${callInfos.duration} seconds`;
      addCallLog('call_ended', callInfos, message);
      setStatusMessage('#call-events', 'warning', message);
    });

    // comment saved
    phone.on('comment_saved', callInfos => {
      console.log('comment_saved');
      const message = 'Comment about the last call saved';
      addCallLog('comment_saved', callInfos, message);
      setStatusMessage('#call-events', 'success', message);
    });

    // outgoing call
    phone.on('outgoing_call', callInfos => {
      console.log('outgoing_call');
      const message = `Outgoing call from ${callInfos.from} to ${callInfos.to} ...`;
      addCallLog('outgoing_call', callInfos, message);
      setStatusMessage('#call-events', 'success', message);
    });

    // outgoing call answered
    phone.on('outgoing_answered', callInfos => {
      console.log('outgoing_answered');
      const message = 'Outgoing call answered!';
      addCallLog('outgoing_answered', callInfos, message);
      setStatusMessage('#call-events', 'success', message);
    });


    // dial button clicked
    document.querySelector('#dial-button').addEventListener(
      'click',
      () => {

        setStep1_loading(true);

        let data = new URLSearchParams();
        data.append('outdial_number', document.getElementById("number").value);
     
        fetch(api_url+"/outdial", {
          //mode: 'no-cors',
          //crossDomain:true,
          //changeOrigin : true,
          method: 'POST',
          headers: new Headers({
            'Accept': '*/*',
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-API-KEY': paytia_apikey,
          }),
          body: data
        })
        .then(response => {
            response.json().then(respond => {
              console.log(respond);
              setOutdialResponse(respond);
              setStep1('none');
              setStep2('block');
              phone.send('dial_number', { phone_number: respond.merchant_telephone }, (success, data) => {
                setPhoneVisibility(true);
                //setStatusData('#dial-info', data, `// first argument\n${success}\n// second argument`);
                !!success
                  ? setStatusMessage('#send-event-status-box', 'success', 'Dialing action was a success!')
                  : setStatusMessage('#send-event-status-box', 'danger', data.message);
              });

            })
        })
        .catch(error => {
          console.error(error);
        });

       
      },
      false
    );


  };

  const handleChange = (e) => {
    console.log(e.target.value);
    setNumber(e.target.value);
  };


  const payment_process = () => {

      setStep2_loading(true);

      let data = new URLSearchParams();
      data.append('first_name', firstName);
      data.append('last_name', lastName);
      data.append('amount', amount);
      data.append('calltype', 'outbound');
      data.append('process_id', outdialResponse.process_id);

      fetch(api_url+"/paymenttaken", {
        method: 'POST',
        headers: new Headers({
          'Accept': '*/*',
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-API-KEY': paytia_apikey,
        }),
        body: data
      })
      .then(response => {
          response.json().then(respond => {
            console.log(respond);
            setPaymentProcess(respond);
            setStep2('none');
            setStep3('block');
            setPaymentStatus(true);
            
            
          })
      })
      .catch(error => {
        console.error(error);
      });
  }


  useEffect(() => {
    if(paymentStatus==true){
      const interval = setInterval(() => {
        
        let data = new URLSearchParams();
        data.append('process_id', outdialResponse.process_id);

        fetch(api_url+"/paymentstatus", {
          method: 'POST',
          headers: new Headers({
            'Accept': '*/*',
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-API-KEY': paytia_apikey,
          }),
          body: data
        })
        .then(response => {
            response.json().then(respond => {
              //console.log(respond);
              setPaymentResponse(respond);
              //console.log(respond.card_status);
              if (respond.is_completed == '1') {
                clearInterval(interval);
              }
            })
        })
        .catch(error => {
          console.error(error);
        });


      }, 2000);
      return () => clearInterval(interval);
    }
  }, [paymentStatus]);
  
  useEffect(() => {
    load_aircall_screen();
  }, []);

  return (
    <html>
      <head>
       {/*
      <meta name="referrer" content="same-origin"/>
       */ }
      </head>  
    <div>
        <div id="phone-container" className="d-none">
          <div className="arrow-up"></div>
          <div id="phone"></div>
        </div>
    

        <main role="main">

    
          <div className="container">

            <div style={{marginTop: '30px'}}>
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                  <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                      <li className="nav-item ">
                        <a className="nav-link" style={{cursor: 'pointer'}} onClick={() => history.push('/calling/inbound')}>Inbound</a>
                      </li>
                      <li className="nav-item active">
                        <a className="nav-link" style={{cursor: 'pointer'}} onClick={() => history.push('/calling/outbound')}>Outbound</a>
                      </li>
                    
                    </ul>
                  </div>
                </nav>
              </div>
    
            <h2>Aircall Phone - Outbound</h2>
    
            <div className="row">
              <div className="left">
                <div className="alert alert-danger" id="phone-loading">
                  Phone is not loaded or logged in.
                </div>
              </div>
            </div>
        

            <div className="row" style={{display: step1}}>
              <div className="left">

                <form>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Telephone number to call</label>
                    <input type="text" className="form-control" name="number" id="number" aria-describedby="emailHelp" placeholder="Customer number"  onChange={handleChange} />
                  </div>

                  {(!step1_loading) &&
                    <button type="button" className="btn btn-primary" id="dial-button" disabled={phonelDialButton}>Submit</button>
                  }

                  {(step1_loading) &&
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  }
                </form>

              </div> 
            </div>


            <div className="row" style={{display: step2}}>
              <div className="left">
                <form>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">First Name</label>
                    <input type="text" className="form-control" name="fname" id="fname"  placeholder="First Name"  onChange={ (e) => setFirstName(e.target.value)} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Last Name</label>
                    <input type="text" className="form-control" name="lname" id="lname"  placeholder="Last Name"  onChange={ (e) => setLastName(e.target.value)} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Amount</label>
                    <input type="text" className="form-control" name="amount" id="amount"  placeholder="Amount" onChange={ (e) => setAmount(e.target.value)} />
                  </div>

                  {(!step2_loading) &&
                    <button type="button" onClick={() => payment_process()} className="btn btn-primary" id="payment-button" >Submit Payment Request</button>
                  }
                  {(step2_loading) &&
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  }
                </form>

              </div> 
            </div>

            <div className="row" style={{display: step3}}>
              <div className="left">
                <div className="card">
                  <div className="card-body">
                    <div className="form-group">
                          <label className="col-sm-4 col-form-label">Status</label>
                          <label className="col-sm-8 col-form-label">Transaction Started</label>
                      </div>
                      <div className="form-group">
                        <label className="col-sm-4 col-form-label">Card Number</label>
                        <label className="col-sm-8 col-form-label">{(paymentResponse.card_number)? paymentResponse.card_number : 'XXXXXXXXXXXXXXXX'}
                        <span style={{position: 'absolute',right: '50px'}} id="total_card_number">( {(paymentResponse.totalcardNumber)? paymentResponse.totalcardNumber : ''})</span>
                        <span id="card_status" className="cartStat"><img src={paymentResponse.card_status}/></span>
                        </label>
                      </div>
                      <div className="form-group">
                        <label className="col-sm-4 col-form-label">Card Expiry Date</label>
                        <label className="col-sm-8 col-form-label">{(paymentResponse.expiry_date)? paymentResponse.expiry_date : 'MM YYYY'}
                          <span id="expiry_status" className="cartStat"><img src={paymentResponse.expiry_status}/></span>
                        </label>
                      </div>
                      <div className="form-group">
                        <label className="col-sm-4 col-form-label">Card Security Number</label>
                        <label className="col-sm-8 col-form-label">{(paymentResponse.cvv) ? paymentResponse.cvv : 'XXX'}
                          <span id="cvv_status" className="cartStat"><img src={paymentResponse.cvv_status}/></span>
                        </label>
                      </div>
                      <div className="form-group">
                        <label className="col-sm-4 col-form-label">Authorization code</label>
                        <label className="col-sm-8 col-form-label">{paymentResponse.auth_code}</label>
                      </div>
                      <div className="form-group">
                        <label className="col-sm-4 col-form-label">Acquirer message</label>
                        <label className="col-sm-8 col-form-label">{paymentResponse.acquirer_message}</label>
                      </div>
                    </div>
                  </div>
                </div> 
            </div>


            <div className="row" style={{display: step2}}>
              <div className="left">  
    
                <div className="alert alert-warning" id="send-event-status-box">
                  Waiting events to be sent...
                </div>
                <div id="call-events-log"></div>
                <div className="alert alert-warning" id="call-events">
                  Not in a call
                </div>
              </div>
              <div className="right">
       
              </div>
            </div>

          </div>
        </main>

    
       
    </div>
    </html>
  );

}

export default OutboundCallingPage;
