import React from 'react';
import {  Router, Route, withRouter, Switch } from 'react-router-dom';
import './App.css';

import './reset.scss';
import './demo.scss';

import  {history}  from './helper/history';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'jquery/src/jquery'; 


import  HomePage  from './pages/HomePage';
import  CallbackPage  from './pages/CallbackPage';
import  InboundCallingPage  from './pages/InboundCallingPage';
import  OutboundCallingPage  from './pages/OutboundCallingPage';

function App() {

  return (
    <Router history={history}>
        <div>
        <Switch>
            <Route
                exact = {true}
                path={'/'}
                component={HomePage}
            /> 
            <Route
                path={'/callback'}
                component={CallbackPage}
            /> 
            <Route
                path={'/calling/inbound'}
                component={InboundCallingPage}
            /> 
             <Route
                path={'/calling/outbound'}
                component={OutboundCallingPage}
            /> 
          </Switch> 
      </div>
    </Router>  
  );

}

export default App;
