const env = 'dev'; 


let api_url = 'https://accounts.pay729.guru/member/marketplace/api';
let AIRCALL_API_URL="https://api.aircall.io";

let oauthUrl = 'https://dashboard-v2.aircall.io/oauth/authorize';
let oauth_success_url= "https://dashboard-v2.aircall.io/oauth/success";

let oauth_clientID = '5K9-Rk66vAf0lPJEDavCx6KawCvABwpCkFT7PTVqMH0';
let oauth_secret = 'TClY3azFQP5BGISjWzBM_A-2G7b6ooCXwFLl0A_lGpE';
let oauth_redirect_uri = "https://aircalldemo.protechgenie.in/callback";

let aircall_loginurl = oauthUrl+'?client_id='+oauth_clientID+'&redirect_uri='+oauth_redirect_uri+'&response_type=code&scope=public_api';

export { api_url, AIRCALL_API_URL, oauth_clientID, oauth_secret , oauth_redirect_uri, aircall_loginurl } ;
