import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";

import AircallPhone from 'aircall-everywhere';
import JSONPretty from 'react-json-pretty';

import $ from 'jquery';

import {aircall_loginurl}  from '../config/config';

function HomePage() {

  const [callType, setCallType] = useState('inbound');

  const [signButton, setSignButton] = useState('block');
  const [apiKey, setApiKey] = useState('');

  const paytia_signin = () => {
    console.log(apiKey);
    localStorage.setItem('paytia_apikey', apiKey);
    setSignButton('none');
    $('#terms').modal('hide');
    window.open(aircall_loginurl,"_self");

  }

  const selectCallType = (e) => {
    console.log(e.target.value);
    setCallType(e.target.value);
  }


  return (
    <html>
      <div>

        <main role="main">

    
          <div className="container">
    
            <h2>Paytia Authentication</h2>
            
            <div className="row" style={{display: signButton}}>
              <div className="left">
                <div className="form-group">
                
                    <button type="button" className="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg">Signin with Paytia</button>

                </div> 
              </div> 
            </div>     

          </div>
        </main>

        <div className="modal fade bd-example-modal-lg" id="terms" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-mg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Signin with Paytia</h5>
                </div> 
                <div className="modal-body">
                  <div className="form-group">
                    <label htmlFor="recipient-name" className="col-form-label">API Key</label>
                    <input type="text" className="form-control" name="api_key" id="api_key"  onChange={ (e) => setApiKey(e.target.value)}/>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary next-step float-right" onClick={() => paytia_signin() }>Submit</button>
                </div>

              </div>
          </div>
        </div>
       
    </div>
    </html>
  );
}

export default withRouter(HomePage);
