import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";

import AircallPhone from 'aircall-everywhere';
import JSONPretty from 'react-json-pretty';

import  {history}  from '../helper/history';

import {api_url ,AIRCALL_API_URL, oauth_clientID, oauth_secret, oauth_redirect_uri}  from '../config/config';
import queryString from 'query-string';

function CallbackPage(props) {

  useEffect(() => {

    const values = queryString.parse(props.location.search)
    console.log(values);

    if(values.code!=""){

        let data = {
            "client_id" : oauth_clientID,
            "client_secret" :  oauth_secret,
            "code" : values.code,
            "redirect_uri" : oauth_redirect_uri,
            "grant_type" : "authorization_code"
        }

        fetch(AIRCALL_API_URL+"/v1/oauth/token", {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/json'
          }),
          body: JSON.stringify(data)
        })
        .then(response => {
          if (response.status != 401) {
            response.json().then(respond => {
              console.log(respond);
              localStorage.setItem('aircall_data', respond);
              //history.push('/calling/inbound');
              history.push({
                pathname: '/calling/inbound',
                search: '',
              })
            })
          }else{
            history.push({
              pathname: '/',
              search: '',
            })
          }  

        })
        .catch(error => {
          console.error(error);
        });
    }

   
  }, []);
  
  
  return (
    <html>
      <body> 
        <div className="d-flex justify-content-center">
         
          <div className="spinner-grow" role="status" style={{position: 'absolute',top: '45%',width: '5rem', height: '5rem'}}>
  <span className="sr-only">Loading...</span>
</div>
        </div>
      </body> 
    </html>
  );
}

export default withRouter(CallbackPage);
